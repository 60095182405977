const devicesEndpoint = `${process.env.VUE_APP_API_URL}api/devices/`

export default {
  namespaced: true,
  state: {
    devicesList: [],
    devicesListBanner: [],
    currentDevice: {},
    generalStatus: {},
    timeInterval: 2,
    generalStatusBySite: {},
    generalStatusHomeInfo: {},
    offlineDevices: [],
    devicesWithAlert: [],
    currentAmbTempStatus: 0,
    currentCondenserTemp: 0,
    currentDevicerRoles: {},
    currentMetricBlastChiller: {},
    currentMetricGenericDevice: {},
    current_total_consumption:0,
    email_list_for_device:[],
    graph_loadings:{
      loading_adeq_top:false,
      loading_adeq_hum:false,
      loading_envol_temp:false,
      loading_sub_cond:false,
      loading_potency:false,
      loading_perform_effor:false,
      loading_evaporator_overheating:false,
      loading_defrost_equipment_1:false,
      loading_defrost_equipment_2:false,
      loading_defrost_equipment_3:false
    },
    graph_loadings_blast_chill:{
      loading_adeq_top:false,
      loading_adeq_hum_int:false,
      loading_operation:false,
      loading_temp_amb_hum_rel:false,
      loading_cond_undercooling:false,
      loading_energy_consumption:false,
      loading_potency:false,
      loading_evaporator_overheating:false,
    }
  },
  getters: {},
  mutations: {
    SET_LOADING_STATE_DONE_GRAPH(state, data) {
      state.graph_loadings[data] = false
    },
    SET_LOADING_STATE_START_GRAPH(state, data) {
      state.graph_loadings[data] = true
    },
    SET_LOADING_STATE_DONE_GRAPH_BLAST_CHILL(state, data) {
      state.graph_loadings_blast_chill[data] = false
    },
    SET_LOADING_STATE_START_GRAPH_BLAST_CHILL(state, data) {
      state.graph_loadings_blast_chill[data] = true
    },
    SET_TIME_INTERVAL(state, data) {
      state.timeInterval = data
    },
    CLEAR_CURRENT_DEVICE(state) {
      state.currentDevice = {};
    },
    SET_DEVICES_LIST(state, data) {
      state.devicesList = data
    },
    SET_CURRENT_DEVICE(state, data) {
      state.currentDevice = data;
    },
    SET_CURRENT_DEVICE_ROLES(state, data) {
      state.currentDevicerRoles = data;
    },
    SET_DEVICE_DESCRIPTION(state, data) {

      let updatedDevice = { ...state.currentDevice };

      updatedDevice.description = data;

      state.currentDevice = updatedDevice;
    },
    SET_DEVICE_NAME(state, data) {

      let updatedDevice = { ...state.currentDevice };

      updatedDevice.name = data;

      state.currentDevice = updatedDevice;
    },
    SET_DEVICE_LOCATION(state, data) {

      let updatedDevice = { ...state.currentDevice };

      updatedDevice.location = data;

      state.currentDevice = updatedDevice;
    },
    SET_DEVICE_TIMEZONE(state, data) {

      let updatedDevice = { ...state.currentDevice };

      updatedDevice.timezone = data;

      state.currentDevice = updatedDevice;
    },
    SET_GENERAL_STATUS(state, data) {
      state.generalStatus = data
    },
    SET_GENERAL_STATUS_BY_SITE(state, data) {
      state.generalStatusBySite = data
    },
    SET_GENERAL_STATUS_HOME_INFO(state, data) {
      state.generalStatusHomeInfo = data
    },
    SET_OFFLINE_DEVICES_LIST(state, data) {
      state.offlineDevices = data
    },
    SET_DEVICES_BANNER_LIST(state, data) {
      state.devicesListBanner = data
    },
    UPDATE_DEVICES_BANNER_LIST(state, data) {
      state.devicesListBanner.forEach(element => {
        if (element.id == data.device_id)
          element.last_metrics = data
      });
    },
    UPDATA_DEVICES_BANNER_LIST_POWER_CONSUMPTION(state, data) {
      let devicesListBannerNew = [...state.devicesListBanner]
      devicesListBannerNew.forEach(element => {
        if (element.id == data.deviceId) {
          element.last_consumption = Number(data.data.closerPeriod / 1000).toFixed(3);
          element.consumption_comparison = Number((data.data.closerPeriod - data.data.distantPeriod) / 1000).toFixed(3)
        }
      });
      state.devicesListBanner = devicesListBannerNew;
    },
    SET_DEVICE_AVG_POTENCY(state, data) {

      let updatedDevice = { ...state.currentDevice };

      updatedDevice["avg_potency"] = data;

      state.currentDevice = updatedDevice;
    },
    SET_DEVICE_TOTAL_CONSUMPTION(state, data) {
      state.current_total_consumption = data;
    },
    SET_DEVICE_CURRENCY(state, data) {

      let updatedDevice = { ...state.currentDevice };

      updatedDevice.currency = data;

      state.currentDevice = updatedDevice;
    },
    SET_DEVICE_ENERGY_PRICE(state, data) {

      let updatedDevice = { ...state.currentDevice };

      updatedDevice.energy_price = data;

      state.currentDevice = updatedDevice;
    },
    SET_DEVICES_WITH_ALERT_LIST(state, data) {
      state.devicesWithAlert = data
    },
    SET_DEVICE_STATUS_GRAPH_AT(state, data) {
      state.currentAmbTempStatus = data;
    },
    SET_DEVICE_STATUS_GRAPH_CONDENSER(state, data) {
      state.currentCondenserTemp = data;
    },
    SET_BLAST_CHILL_DETAILS(state, data) {
      let newData = data;
      state.currentMetricBlastChiller = { ...newData };
    },
    SET_GENERIC_DEVICE_DETAILS(state, data) {
      let newData = data;
      state.currentMetricGenericDevice = { ...newData };
    },
    SET_LIST_CURRENT_USERS_DEVICE(state, data){
      state.email_list_for_device = data
    }
  },
  actions: {
    getRegisteredDevices({ commit }, { context, typology, search }) {  //context -> for later adapt with interceptors
      //FILTERS
      let params = new URLSearchParams({
        typology: typology ? typology.id : "",
        search: search ? search : ""
      });

      //GET DEVICES
      context.$http.get(`${devicesEndpoint}?${params.toString()}`)
        .then(res => commit("SET_DEVICES_LIST", res.data));
    },
    getSingleDevice({ commit }, { id, context }) {
      context.$http.get(`${devicesEndpoint}${id}`)
        .then(res => commit("SET_CURRENT_DEVICE", res.data));
    },
    getSingleDeviceRoles({ commit }, { id, context }) {
      context.$http.get(`${devicesEndpoint}${id}/get-users-roles-device/`)
        .then(res => commit("SET_CURRENT_DEVICE_ROLES", res.data));
    },
    getEmailSingleDeviceRoles({ commit }, { id, context }) {
      return context.$http.get(`${devicesEndpoint}${id}/get-users-email-device/`)
        .then(res => commit("SET_LIST_CURRENT_USERS_DEVICE", res.data));
    },
    updateDeviceDetails({ state }, { context }) {
      let data = {
        "description": state.currentDevice.description,
        "name": state.currentDevice.name,
        "location": state.currentDevice.location,
        "timezone": state.currentDevice.timezone,
        "currency": state.currentDevice.currency,
        "energy_price": state.currentDevice.energy_price ? state.currentDevice.energy_price : null
      }

      context.$http.patch(`${devicesEndpoint}${state.currentDevice.id}/`, data);
    },
    getGeneralStatus({ commit }, { context, initial_date, end_date, home_info }) {
      let params = new URLSearchParams({
        initial_date: initial_date,
        end_date: end_date,
        home_info: "",
      });

      // GET GENERAL STATUS
      context.$http.get(`${devicesEndpoint}general-status/?${params.toString()}`)
        .then(res => {
          if (home_info == "Now" || home_info == "") {
            commit("SET_GENERAL_STATUS_HOME_INFO", res.data);
          }
          commit("SET_GENERAL_STATUS", res.data);
        });

    },
    getGeneralStatusPDF({ commit }, { context, initial_date, end_date }) {
      let params = new URLSearchParams({
        initial_date: initial_date,
        end_date: end_date,
      });

      // GET GENERAL STATUS
      return context.$http.get(`${devicesEndpoint}general-status-pdf/?${params.toString()}`);

    },
    getGeneralStatusBySite({ commit }, { context, location }) {
      let params = new URLSearchParams({
        location: location ? location : ""
      });

      // GET GENERAL STATUS BY Site
      context.$http.get(`${devicesEndpoint}general-status-by-site/?${params.toString()}`)
        .then(res => commit("SET_GENERAL_STATUS_BY_SITE", res.data));
    },
    getGeneralStatusBySitePDF({ commit }, { context, location }) {
      let params = new URLSearchParams({
        location: location ? location : "",
      });

      // GET GENERAL STATUS
      return context.$http.get(`${devicesEndpoint}general-status-by-site-pdf/?${params.toString()}`);

    },
    getOfflineDevices({ commit }, { context, typology, search }) {
      let params = new URLSearchParams({
        typology: typology ? typology.id : "",
        search: search ? search : "",
      });

      context.$http.get(`${devicesEndpoint}offline-device/?${params.toString()}`)
        .then(res => {
          commit("SET_OFFLINE_DEVICES_LIST", res.data);
        });
    },
    getAlertsDeviceList({ commit }, { context }) {

      context.$http.get(`${devicesEndpoint}list-alerts-devices/`)
        .then(res => {
          commit("SET_DEVICES_WITH_ALERT_LIST", res.data);
        });
    },
    getBlastChillMetric({ commit }, { context, id }) {
      context.$http.get(`${devicesEndpoint}${id}/blast-chiller-metric/`)
        .then(res => commit("SET_BLAST_CHILL_DETAILS", res.data));
    },
    getGenericDevicelMetric({ commit }, { context, id }) {
      context.$http.get(`${devicesEndpoint}${id}/generic-metric/`)
        .then(res => commit("SET_GENERIC_DEVICE_DETAILS", res.data));
    },
  },
}
