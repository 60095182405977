const alertEndpoint = `${process.env.VUE_APP_API_URL}api/alerts/`

export default {
  namespaced: true,
  state: {
    alertList:[],
    currentAlert:{}
  },
  getters: {},
  mutations: {
    SET_ALERTS_LIST(state, data) {
      state.alertList = data
    },
  },
  actions: {
    report({ commit }, { context, initial_date, end_date, device_id, is_haccp, interval }) {
      let params = new URLSearchParams({
        initial_date: initial_date ? initial_date: '',
        end_date: end_date ? end_date: '',
        device: device_id ? device_id: '',
        is_haccp: is_haccp ? is_haccp: '',
        interval: interval ? interval: ''
      });

      // GET REPORT ALERT
      return context.$http.get(`${alertEndpoint}report/?${params.toString()}`)
    },
    listAlerts({ commit }, { context, device_id, interval }) {
      let params = new URLSearchParams({
        device: device_id,
        interval: interval
      });

      // GET LIST ALERT
      return context.$http.get(`${alertEndpoint}?${params.toString()}`)
      .then(res => commit("SET_ALERTS_LIST", res.data.filter(x => x.is_hidden != true)))
      
    },
  },
}
