import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import OneSignal from "onesignal-vue";

import i18n from '@/libs/i18n'
import router from './router'
import store from './store'
import App from './App.vue'

import * as Sentry from "@sentry/vue";
// Global Components
import './global-components'

// 3rd party plugins
import '@axios'
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/clipboard'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'
import '@/libs/tour'
import 'vue-phone-number-input/dist/vue-phone-number-input.css'

// Axios Mock Adapter
import '@/@fake-db/db'

import {roles} from '@/roles/roles'
import responsive from 'vue-responsive'

Vue.prototype.$roles = roles

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Push Notifications PLugin
Vue.use(OneSignal)

// Composition API
Vue.use(VueCompositionAPI)

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

//responsive
Vue.config.productionTip = false


Sentry.init({
  Vue,
  dsn: process.env.VUE_APP_SENTRY_DSN,
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracePropagationTargets: ["localhost", "dialog-iot-web-qa.s3-website-eu-west-1.amazonaws.com","iot.mercatus.pt/",/^\//],
    }),
    new Sentry.Replay()
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  environment: process.env.VUE_APP_SENTRY_ENV,
});

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
  beforeMount() {
    this.$OneSignal.init({
      appId: `${process.env.VUE_APP_ONE_SIGNAL_APP_ID}`,
      allowLocalhostAsSecureOrigin: true,
    });
  },
}).$mount('#app')
