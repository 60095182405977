import mock from '@/@fake-db/mock'

const date = new Date()
const nextDay = new Date(new Date().getTime() + 24 * 60 * 60 * 1000)
// prettier-ignore
const nextMonth = date.getMonth() === 11 ? new Date(date.getFullYear() + 1, 0, 1) : new Date(date.getFullYear(), date.getMonth() + 1, 1)
// prettier-ignore
const prevMonth = date.getMonth() === 11 ? new Date(date.getFullYear() - 1, 0, 1) : new Date(date.getFullYear(), date.getMonth() - 1, 1)

const data = {
  events: [
    {
      id: 1,
      url: '',
      title: 'Pre-cooling',
      start: new Date('2023-03-20T10:30:00'),
      end: new Date('2023-03-20T11:00:00'),
      allDay: false,
      extendedProps: {
        calendar: 'Common',
        icon: 'temperature.png'
      },
    },
    {
      id: 2,
      url: '',
      title: 'Chilling',
      start: new Date('2023-03-20T11:00:00'),
      end: new Date('2023-03-20T12:30:00'),
      allDay: false,
      extendedProps: {
        calendar: 'Common',
        icon: 'chill.png'
      },
    },
    {
      id: 3,
      url: '',
      title: 'Holding',
      start: new Date('2023-03-20T12:30:00'),
      end: new Date('2023-03-20T14:00:00'),
      allDay: false,
      extendedProps: {
        calendar: 'Holding',
        icon: 'holding.svg'
      },
    },
    {
      id: 4,
      url: '',
      title: 'Freezing',
      start: new Date('2023-03-21T00:00:00'),
      end: new Date('2023-03-22T00:00:00'),
      allDay: false,
      extendedProps: {
        calendar: 'Common',
        icon: 'freezing.png'
      },
    },
    {
      id: 4,
      url: '',
      title: 'Hardening',
      start: new Date('2023-03-22T10:30:00'),
      end: new Date('2023-03-22T10:45:00'),
      allDay: false,
      extendedProps: {
        calendar: 'Special',
        icon: 'hardening.png'
      },
    },
    {
      id: 5,
      url: '',
      title: 'Vegetables',
      start: new Date('2023-03-22T11:45:00'),
      end: new Date('2023-03-22T13:15:00'),
      allDay: false,
      extendedProps: {
        calendar: 'Common',
        icon: 'chill.png'
      },
    },
    {
      id: 6,
      url: '',
      title: 'Sanitation',
      start: new Date('2023-03-23T00:00:00'),
      end: new Date('2023-03-24T00:00:00'),
      allDay: false,
      extendedProps: {
        calendar: 'Special',
        icon: 'sanitation.png'
      },
    },
    {
      id: 7,
      url: '',
      title: 'Red Meat',
      start: new Date('2023-03-24T10:50:00'),
      end: new Date('2023-03-24T12:25:00'),
      allDay: false,
      extendedProps: {
        calendar: 'Common',
        icon: 'chill.png'
      },
    },
    {
      id: 8,
      url: '',
      title: 'Continuous',
      start: new Date('2023-03-25T10:00:00'),
      end: new Date('2023-03-25T20:00:00'),
      allDay: false,
      extendedProps: {
        calendar: 'Common',
        icon: 'continuous.png'
      },
    },
  ],
}

// ------------------------------------------------
// GET: Return calendar events
// ------------------------------------------------
mock.onGet('/apps/calendar/events').reply(config => {
  // Get requested calendars as Array
  const calendars = config.params.calendars.split(',')

  return [200, data.events.filter(event => calendars.includes(event.extendedProps.calendar))]
})

// ------------------------------------------------
// POST: Add new event
// ------------------------------------------------
mock.onPost('/apps/calendar/events').reply(config => {
  // Get event from post data
  const { event } = JSON.parse(config.data)

  const { length } = data.events
  let lastIndex = 0
  if (length) {
    lastIndex = data.events[length - 1].id
  }
  event.id = lastIndex + 1

  data.events.push(event)

  return [201, { event }]
})

// ------------------------------------------------
// POST: Update Event
// ------------------------------------------------
mock.onPost(/\/apps\/calendar\/events\/\d+/).reply(config => {
  const { event: eventData } = JSON.parse(config.data)

  // Convert Id to number
  eventData.id = Number(eventData.id)

  const event = data.events.find(e => e.id === Number(eventData.id))
  Object.assign(event, eventData)

  return [200, { event }]
})

// ------------------------------------------------
// DELETE: Remove Event
// ------------------------------------------------
mock.onDelete(/\/apps\/calendar\/events\/\d+/).reply(config => {
  // Get event id from URL
  let eventId = config.url.substring(config.url.lastIndexOf('/') + 1)

  // Convert Id to number
  eventId = Number(eventId)

  const eventIndex = data.events.findIndex(e => e.id === eventId)
  data.events.splice(eventIndex, 1)
  return [200]
})
