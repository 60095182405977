export default {
  namespaced: true,
  state: {
    locations: {
    },
    locationFilter: null
  },
  getters: {},
  mutations: {
    ADD_UPDATE_LOCATION_IMAGE(state, { location, image }) {
      state.locations[location] = image;
    },
    SET_LOCATION_FILTER(state, location) {
      if (state.locationFilter == location) {
        state.locationFilter = "";
      } else {
        state.locationFilter = location;
      }

    }
  },
  actions: {},
}
