const subscriptionEndpoint = `${process.env.VUE_APP_API_URL}api/payment`

export default {
  namespaced: true,
  state: {
    total_value: 0,
    total_equipments: 0,
    selected_equipments: [],
    plan_id: '',
    devicesListSubscription: [],
    ExpiredDetail:{}
  },
  getters: {},
  mutations: {
    SET_SUBSCRIPTION_LIST(state, data) {
      state.devicesListSubscription = data
    },
    SET_TOTAL_VALUE_PLAN(state, data) {
      state.total_value = data
    },
    SET_TOTAL_EQUIPMENTS_PLAN(state, data) {
      state.total_equipments = data
    },
    SET_LIST_EQUIPMENTS_PLAN(state, data) {
      state.selected_equipments = data
    },
    SET_SUBSCRIPTION_EXPIRED(state, data) {
      state.ExpiredDetail = data
    }
  },
  actions: {
    getDevicesListSubscription({ commit }, { context }) {  //context -> for later adapt with interceptors
      context.$http.get(`${subscriptionEndpoint}/list-subscriptions/`)
      .then(res => commit("SET_SUBSCRIPTION_LIST", res.data));
    },
    getVerifySubscription({ commit }, { context, deviceId }) {  //context -> for later adapt with interceptors
      let params = new URLSearchParams({
        device: deviceId
      });
      context.$http.get(`${subscriptionEndpoint}/verify-expiration?${params.toString()}`)
      .then(res => commit("SET_SUBSCRIPTION_EXPIRED", res.data));
    },
    partialCancelSubscription({ commit }, { context, data }){
      return context.$http.post(`${subscriptionEndpoint}/partial-cancel-subscription/`, data )
    },
    getSubscription({ commit }, { context, subscription_id }){
      let params = new URLSearchParams({
        subscription_id: subscription_id
      });
      return context.$http.get(`${subscriptionEndpoint}/get-subscription?${params.toString()}`)
    },
    cancelSubscription({ commit }, { context, subscription_id}){
      let data = {subscription_id:subscription_id}
      return  context.$http.post(`${subscriptionEndpoint}/cancel-subscription/`, data )
    },
  },
}
