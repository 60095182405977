import mock from '@/@fake-db/mock'
/* eslint-disable global-require */
const data = {
  tableSimulation: [
    {
      id: 1,
      example1: 'Cabinet',
      example2: '180306',
      example3: 'M1-760',
      example4: 'Single door GN2/1 Refrigerator Cabinet Sense',
      example5: '2019-03-10 15:00',
      example6: '2019-03-10 15:00',
      example7: 'Activo',
    },
    {
      id: 2,
      example1: 'Cabinet',
      example2: '180306',
      example3: 'M1-760',
      example4: 'Single door GN2/1 Refrigerator Cabinet Sense',
      example5: '2019-03-10 15:00',
      example6: '2019-03-10 15:00',
      example7: 'Alerta',
    },
    {
      id: 3,
      example1: 'Cabinet',
      example2: '180306',
      example3: 'M1-760',
      example4: 'Single door GN2/1 Refrigerator Cabinet Sense',
      example5: '2019-03-10 15:00',
      example6: '2019-03-10 15:00',
      example7: 'Activo',
    },
    {
      id: 4,
      example1: 'Cabinet',
      example2: '180306',
      example3: 'M1-760',
      example4: 'Single door GN2/1 Refrigerator Cabinet Sense',
      example5: '2019-03-10 15:00',
      example6: '2019-03-10 15:00',
      example7: 'Offline',
    },
  ],

  tableSimulation2: [
    {
      id: 1,
      example1: '172527',
      example2: 'Offline device',
      example3: 'Alert',
      example4: '-',
      example5: 'Test 1',
      example6: 'kitchen',
      example7: '2019-03-10 15:00',
      example8: 'Alert error',


    },
    {
      id: 1,
      example1: '172527',
      example2: 'Offline device',
      example3: 'Alert',
      example4: '-',
      example5: 'Test 2',
      example6: 'kitchen',
      example7: '2019-03-10 15:00',
      example8: 'Alert error',
    },
    {
      id: 1,
      example1: '172527',
      example2: 'Offline device',
      example3: 'Alert',
      example4: '-',
      example5: 'Test 3',
      example6: 'kitchen',
      example7: '2019-03-10 15:00',
      example8: 'Alert error',
    },
  ],
}

mock.onGet('/good-table/simulation').reply(() => [200, data.tableSimulation])
mock.onGet('/good-table/simulation2').reply(() => [200, data.tableSimulation2])