export default [
  {
    path: '/home',
    name: 'home',
    component: () => import('@/views/home/home-1/Home.vue'),
  },
  {
    path: '/home-2',
    name: 'home-2',
    component: () => import('@/views/home/home-2/Home2.vue'),
  },
]
